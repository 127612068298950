<template>
  <card class="inner-card question-card">
    <template #title>Pregunta de Seguridad</template>
    <template #content>
      <h6 class="red">
        Los códigos de Verificaión se enviarán al correo elctrónico registrado.
      </h6>
      <hr />
      <div class="flex flex-column align-items-center justify-content-center">
        <h5>Registre su pregunta de seguridad</h5>
        <br />
        <div class="flex flex-column justify-items-center">
          <div class="inline-flex justify-content-center">
            <h8>Ingrese su pregunta de Seguridad</h8>
            <h8 class="red ml-1"> * </h8>
          </div>
          <Input type="text" id="Question" autocomplete="off" class="label" />
          <br />
          <div class="inline-flex justify-content-center">
            <h8>Ingrese su Respuesta</h8>
            <h8 class="red ml-1"> * </h8>
          </div>
          <Input
            type="text"
            id="FirstAnswer"
            autocomplete="off"
            class="label"
          />
          <br />
          <div class="inline-flex justify-content-center">
            <h8 class="dist2">Codigo de Verificación</h8>
            <h8 class="red ml-1"> * </h8>
          </div>
          <div class="p-float-label inline-flex">
            <Input
              type="text"
              id="NewMail"
              autocomplete="off"
              class="label dist"
            />
            <button
              class="btn red btn-primary -ml-8"
              type="button"
              @click="sendNotification('sms')"
            >
              Obtener Código
            </button>
          </div>
        </div>
      </div>
      <hr />
      <div class="flex align-items-center justify-content-center">
        <button
          class="btn1 btn-primary ml-1"
          type="button"
          onclick="changeQuestions()"
        >
          Guardar
        </button>
      </div>
    </template>
  </card>
</template>
<script>
export default {
  components: {},
  props: {
    questions: null,
  },
  data() {
    return {
      condition: true,
    };
  },
  mounted() {},
  beforeUnmount() {},
  created() {},
  methods: {
    // ...mapMutations("ticketoffice", [
    //   "setActiveClient",]),
    //   changeQuestions(){
    // },
  },
  computed: {},
  watch: {},
};
</script>

<style lang="scss" scoped>
.question-card {
  border: 0.5px solid rgba(0, 0, 0, 0.38);
  border-radius: 10px;
}

.btn {
  background-color: transparent;
  color: #cc195a;
  border: 0.5px solid #474444;
  border-radius: 5px;
  width: 50px;
  height: 25px;
  border-style: none;
}

.btn1 {
  width: 180px;
  height: 40px;
  background: #cc195a;
  border-radius: 0px;
  color: #ffffff;
  border-radius: 5px;
  border-color: #cc195a;
  font-family: Roboto;
  font-size: 15px;
  border-style: none;
}

.btn2 {
  background-color: transparent;
  color: #cc195a;
  border-radius: 5px;
  border-width: 1px;
  border-color: #cc195a;
  width: 140px;
}

.btn2:hover {
  cursor: pointer;
}

h8 {
  color: #424141;
}

.label {
  height: 30px;
  width: 250px;
  background-color: #eaeaea;
  border-radius: 5px;
  border-color: transparent;
}

.question {
  height: 30px;
  background-color: transparent;
  border-radius: 5px;
  border-color: #c4c2c2;
}

.red {
  color: #cc195a;
}

.btn:hover {
  cursor: pointer;
}

.btn1:hover {
  cursor: pointer;
}

@media screen and (max-width: 820px) {
  .btn {
  }
}
</style>
